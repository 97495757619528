export type PaymentMethod = {
  id: number;
  name: string;
};

export enum PaymentMethodEnum {
  AMAZON = 'Amazon',
  COPPEL = 'Coppel',
  LIVERPOOL = 'Liverpool',
  LINIO = 'Linio',
  MERCADO_LIBRE = 'Mercado Libre',
  WALMART = 'Walmart',
}
