import { useState, useEffect, useRef } from 'react';
import { useMutation } from '@apollo/client';
import { CalculatedOrder, CreateOrderDetail } from 'types/orders.types';
import { useToast } from 'hooks';
import { ORDER_CALCULATE } from 'hooks/orders/mutations/orders.mutations';

type Errors = Record<string, string>;
type Variables = {
  patientId?: number;
  shippingId?: number;
  shippingMethodId?: number;
  couponCode?: string;
  details?: CreateOrderDetail[];
  initialOrderTotals?: CalculatedOrder;
  orderId?: number;
  orderStatus?: string;
  paymentMethod?: number;
  paymentStatus?: string;
  pickup?: boolean;
  cdso_transaction?: string;
  orbis_transaction?: string;
  delivery_time_block?: any;
  isSubmitting?: boolean;
};
const useOrderCalculate = ({
  patientId,
  shippingId,
  shippingMethodId,
  couponCode,
  details,
  initialOrderTotals,
  orderId,
  orderStatus,
  paymentMethod,
  paymentStatus,
  pickup,
  cdso_transaction,
  orbis_transaction,
  delivery_time_block,
  isSubmitting,
}: Variables): [CalculatedOrder | undefined, Errors] => {
  const [calculatedOrder, setCalculatedOrder] = useState<
    CalculatedOrder | undefined
  >(undefined);
  const [openToast] = useToast();
  const [errors, setErrors] = useState<Errors>({});

  const [calculateOrder] = useMutation<{
    calculateOrder: CalculatedOrder;
  }>(ORDER_CALCULATE, {
    onCompleted(data) {
      if (data) {
        const { calculateOrder: calculateOrderData } = data;
        const { errors: errs = {}, ...orderCalc } = calculateOrderData;
        setCalculatedOrder(orderCalc);
        setErrors(errs);
      }
    },
    onError: (e: any) => {

      const transformedErrors = JSON.stringify(e);
      const parsedErrors = JSON.parse(transformedErrors);
      const errorsForm = parsedErrors.networkError.result;

      if (errorsForm?.payment_method) {
        openToast(
          errorsForm.payment_method === 'Cash is only for local shipping.'
            ? 'El pago en efectivo es unicamente para envíos locales'
            : errorsForm.payment_method,
          { type: 'error' }
        );
      }
      if (JSON.stringify(errorsForm.details)) {
        switch (JSON.stringify(errorsForm.details)) {
          case 'Product is only for local shipping.':
            openToast('El producto es unicamente para envíos locales', {
              type: 'error',
            });
            break;

          case 'Prescription product is only for local shipping.':
            openToast(
              'Los productos con receta son unicamente para envíos locales',
              {
                type: 'error',
              }
            );
            break;
          default:
            if (JSON.stringify(errorsForm.details).includes('Product cannot be shipped nationally')) {
              openToast('Este producto no admite envío nacional', { type: 'error' });
            } else {
              openToast(JSON.stringify(errorsForm.details), { type: 'error' });
            }
        }
      }
      if (errorsForm?.details?.[0]?.product) {
        openToast(
          errorsForm?.details?.[0]?.product?.[0]?.includes('Clave primaria')
            ? 'El producto no existe o esta inactivo'
            : errorsForm.details?.[0],
          { type: 'error' }
        );
      }
      if (errorsForm?.shipping) {
        openToast(
          errorsForm.shipping === 'Product is only for local shipping.'
            ? 'El producto es unicamente para envíos locales'
            : errorsForm.shipping,
          { type: 'error' }
        );
      }
      if (
        !errorsForm?.details &&
        !errorsForm?.shipping &&
        !errorsForm?.payment_method
      ) {
        openToast('Error al calcular la orden', { type: 'error' });
      }
    },
  });
  const initialRender = useRef(true);
  useEffect(() => {
    if (isSubmitting) return;
    if (initialRender.current) {
      initialRender.current = false;
      if (initialOrderTotals) {
        setCalculatedOrder({
          coupon: initialOrderTotals.coupon,
          discount: initialOrderTotals.discount,
          iva: initialOrderTotals.iva,
          shipping_method_options: initialOrderTotals.shipping_method_options,
          shipping_discount: initialOrderTotals.shipping_discount,
          selected_shipping_method: initialOrderTotals.selected_shipping_method,
          subtotal: initialOrderTotals.subtotal,
          total: initialOrderTotals.total,
          shipping_price: initialOrderTotals.shipping_price,
          cdso: initialOrderTotals.cdso,
          orbis: initialOrderTotals.orbis,
        });
      }
    } else {
      const validDetails = details?.reduce(
        (acum, curr) => acum && curr.quantity > 0,
        true
      );
      let parseDetails:
        | {
          product: number;
          quantity: number;
          price: number;
        }[]
        | undefined;
      if (validDetails) {
        parseDetails = details?.map((detail) => {
          return {
            product: detail.product,
            quantity: detail.quantity,
            price: Number(detail.price),
          };
        });
        const payload: { [key: string]: any } = {
          order: orderId,
          coupon: couponCode !== '' ? couponCode : undefined,
          details: parseDetails,
          patient: patientId || undefined,
          shipping: shippingId || undefined,
          shipping_method: shippingMethodId || undefined,
          order_status: orderStatus || undefined,
          payment_method: paymentMethod || undefined,
          payment_status: paymentStatus || undefined,
          cdso_transaction: cdso_transaction || undefined,
          orbis_transaction: orbis_transaction || undefined,
          delivery_time_block: delivery_time_block || undefined,
        };
        Object.keys(payload).forEach((key) => {
          if (!payload[key]) {
            delete payload[key];
          }
        });
        calculateOrder({
          variables: {
            input: payload,
          },
        });
      }
    }
  }, [
    //  calculateOrder,
    details,
    initialOrderTotals,
    patientId,
    shippingId,
    shippingMethodId,
    couponCode,
    orderId,
    orderStatus,
    // paymentMethod,
    // paymentStatus,
    cdso_transaction,
    orbis_transaction,
    pickup,
    // delivery_time_block,
  ]);
  return [calculatedOrder, errors];
};
export default useOrderCalculate;
