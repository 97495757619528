import { useMutation } from '@apollo/client';
import { useToast, useConfirmation } from 'hooks';
import { ORDER_DELETE } from 'hooks/orders/mutations/orders.mutations';
import getErrorMessages from 'utils/getErrorMessages';

type TuseOrderDeleteProps = {
  variables: {
    id: string | number;
  };
};

const useOrderDelete = ({ variables }: TuseOrderDeleteProps) => {
  const [openToast] = useToast();
  const [openConfirmation] = useConfirmation();

  const [deleteOrder] = useMutation(ORDER_DELETE, {
    variables,
    refetchQueries: ['ordersPaginated'],
    onCompleted: () =>
      openToast('¡Orden borrada exitosamente!', { type: 'success' }),
  });

  const handleDelete = () => {
    try {
      deleteOrder();
    } catch (e: any) {
      const [, nonFieldErrors] = getErrorMessages(e);
      if (nonFieldErrors.length > 0) {
        openToast(nonFieldErrors[0], { type: 'error' });
      } else {
        openToast('Error al borrar orden.', { type: 'error' });
      }
    }
  };

  return () =>
    openConfirmation({
      title: `Borrar orden #${variables.id}`,
      content: `¿Estás seguro de borrar esta orden?
        Todos los datos de esta orden serán eliminados permanentemente.
        Esta acción no se puede deshacer.`,
      onConfirm: handleDelete,
    });
};

export default useOrderDelete;
