import { Order } from 'types/orders.types'
import { PaymentMethodEnum } from 'types/paymentMethods.types'
import { storesNameEnum } from 'types/stores.types'
import { TagsEnum } from 'types/tags.types'

interface isAmazonSelfShipParams {
  order: Order
}

const useIsAmazonSelfShip = ({ order }: isAmazonSelfShipParams) => {

  if (order.distribution_center !== storesNameEnum.AMAZON_FULL
    && !order.tags.some(tag => tag.tag === TagsEnum.AMAZON_EASYSHIP)
    && order.payment_method === PaymentMethodEnum.AMAZON
  ) {
    return { isAmazonSelfShip: true }
  }

  return { isAmazonSelfShip: false }
}

export default useIsAmazonSelfShip
