import { useMutation } from '@apollo/client';
import { ORDER_ML_GUIDE } from './orders.mutations';

type Data = {
  orderMLGuide: {
    pdf: string;
    fileName: string;
  };
};

const useOrderGenerateMlGuide = () => {
  return useMutation<Data>(ORDER_ML_GUIDE);
};

export default useOrderGenerateMlGuide;
