type APIError = {
  networkError?: {
    result?: {
      [key: string]: any;
    };
    statusCode?: number;
  };
};

type FormErrors = {
  [key: string]: string;
};

type Func = {
  (e: APIError): readonly [FormErrors, string[]];
};

type SafeGetError = (error: any, defaultError: string) => string;

/**
 * Safely recursive find error message from API and return it.
 * If it is not found, return a default error
 * @param error
 * @param defaultError
 */
const safeGetError: SafeGetError = (error, defaultError) => {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error === 'object') {
    // eslint-disable-next-line no-restricted-syntax
    for (const [, value] of Object.entries(error)) {
      return safeGetError(value, defaultError);
    }
  }
  return defaultError;
};

const nonFieldErrorsLabels = ['detail', 'non_field_errors', 'details'];

/**
 * Transform API error response so that it can be
 * used inside forms, alerts, and toasts.
 * @param errors
 */
const getErrorMessages: Func = (errors) => {
  const formErrors: FormErrors = {};
  const nonFieldErrors: string[] = [];

  const resultErrors = errors.networkError?.result;
  const statusCode = errors.networkError?.statusCode;

  if (resultErrors) {
    if (statusCode && statusCode < 500) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(resultErrors)) {
        if (nonFieldErrorsLabels.includes(key)) {
          nonFieldErrors.push(safeGetError(value, 'Error desconocido'));
        } else {
          formErrors[key] = safeGetError(value, 'Error en este campo');
        }
      }
    }
  }

  return [formErrors, nonFieldErrors] as const;
};

export default getErrorMessages;
