import { ServerError, useMutation } from '@apollo/client';
import { SpecificOrder } from 'types/orders.types';
import useToast from 'hooks/useToast';
import { ValuesOrderStatusEnum } from 'components/orders/orders.enums';
import { ORDER_STATUS_UPDATE } from './orders.mutations';

const FIFTH_DAY_ERROR = 'Orders from the past month cannot be updated after the 5th day.'

type useOrderUpdateStatusResponse = {
  updateOrderStatus: {
    id: number,
    order_status: ValuesOrderStatusEnum,
    payment_status: string,
  }
}

const useOrderUpdateStatus = () => {
  const [openToast] = useToast();

  return useMutation<{ updateOrderStatus: SpecificOrder }>(
    ORDER_STATUS_UPDATE,
    {
      refetchQueries: ['ordersPaginated', 'specificOrder'],
      onCompleted: (res: useOrderUpdateStatusResponse) => {

        const updatedOrderId = res?.updateOrderStatus?.id

        if (updatedOrderId) {
          openToast(`Orden ${updatedOrderId} actualizada`, { type: 'success' });
        }
      },
      onError: (error) => {
        const response = (error.networkError as ServerError)?.result || error.graphQLErrors;

        if (JSON.stringify(response).includes(FIFTH_DAY_ERROR)) {
          openToast('No es posible actualizar órdenes del mes pasado después del 5to día del mes', { type: 'error' });
        }

        throw new Error
      }
    }
  );
};

export default useOrderUpdateStatus;
