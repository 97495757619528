import { useQuery } from '@apollo/client';
import { useToast } from 'hooks';
import { SpecificOrder } from 'types/orders.types';
import { ORDER_GET_SPECIFIC } from 'hooks/orders/queries/orders.queries';

const useOrder = (id: number | undefined) => {
  const [openToast] = useToast();
  return useQuery<{ specificOrder: SpecificOrder }>(ORDER_GET_SPECIFIC, {
    fetchPolicy: 'network-only',
    variables: { id },
    skip: !id,
    onError: () => openToast('Error al cargar orden.', { type: 'error' }),
  });
};

export default useOrder;
