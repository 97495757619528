import { ID } from './util.types';

export type Tag = {
  id: ID;
  name: string;
  created_at: Date;
  updated_at?: Date;
};

// **
// * Here we can add the tags that we have in each order
// * There are being added as we need them as enum values
// */
export enum TagsEnum {
  AMAZON_EASYSHIP = 'amazon_easyship',
}
