import { useMutation } from '@apollo/client';
import { Order } from 'types/orders.types';
import { ORDER_CREATE } from './orders.mutations';

const useOrderCreate = () => {
  return useMutation<{ createOrder: Order }>(ORDER_CREATE, {
    refetchQueries: ['ordersPaginated'],
  });
};

export default useOrderCreate;
