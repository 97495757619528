import { useMutation } from '@apollo/client';
import { useToast, useConfirmation } from 'hooks';
import { SpecificOrder } from 'types/orders.types';
import { SpecificSubscriptionOrder } from 'types/subscriptions.types';
import getErrorMessages from 'utils/getErrorMessages';
import { ORDER_INVOICE_CANCEL } from './orders.mutations';

type Variables = {
  order: SpecificOrder | SpecificSubscriptionOrder;
  skipConfirmation?: boolean;
};

const useOrderInvoiceCancel = ({
  order,
  skipConfirmation = false,
}: Variables) => {
  const [openToast] = useToast();
  const [openConfirmation] = useConfirmation();

  const [createInvoice] = useMutation(ORDER_INVOICE_CANCEL, {
    variables: {
      id: order?.id,
      input: {
        patient: order?.patient?.id,
        payment_method: order?.payment_method?.id,
        details: order?.details?.map((detail) => ({
          product: detail.id,
          quantity: detail.quantity,
        })),
      },
    },
    refetchQueries: ['ordersPaginated'],
    onCompleted: () =>
      openToast('¡Factura cancelada exitosamente!', { type: 'success' }),
  });

  const handleCancelInvoice = async () => {
    try {
      await createInvoice({
        variables: {
          id: order.id,
          input: {
            patient: order.patient.id,
            payment_method: order.payment_method.id,
            details: order.details.map((detail) => ({
              product: detail.id,
              quantity: detail.quantity,
            })),
          },
        },
      });
    } catch (e: any) {
      // @ts-ignore
      const [, nonFieldErrors] = getErrorMessages(e);
      if (nonFieldErrors.length > 0) {
        openToast(nonFieldErrors[0] || 'Error al cancelar factura.', {
          type: 'error',
        });
      } else {
        openToast('Error al cancelar factura.', { type: 'error' });
      }
    }
  };

  if (skipConfirmation) {
    return () => handleCancelInvoice();
  }

  return () =>
    openConfirmation({
      title: `Cancelar factura orden #${order.id}`,
      content: `¿Estás seguro de cancelar esta factura?`,
      onConfirm: handleCancelInvoice,
      textConfirmButton: 'Cancelar',
      textCancelButton: 'No',
    });
};

export default useOrderInvoiceCancel;
