import { useContext } from 'react';
import { ExpandedOrdersContext } from 'context';

const useExpandedOrders = () => {
  const context = useContext(ExpandedOrdersContext);
  if (context === undefined) {
    throw new Error(
      'useOrdersPaginated must be used within an OrdersPaginatedProvider'
    );
  }
  return context;
};

export default useExpandedOrders;
