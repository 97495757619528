import { ID } from './util.types';

// distribution_center Enum
// **
// * Here we can add the distribution centers that we have in the system
// * There are being added as we need them as enum values
// */
export enum storesNameEnum {
  SUCURSAL_MONTERREY = 'Sucursal Monterrey',
  AMAZON_FULL = 'Amazon FULL',
}

export type Store = {
  id: ID;
  created_at: Date;
  updated_at: Date;
  street: string;
  exterior_number: string;
  interior_number: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phone: string;
  additional_info: string;
  name: string;
  company_name: string;
};
