import { useMutation } from '@apollo/client';
import { SpecificOrder } from 'types/orders.types';
import { ID } from 'types/util.types';
import { ORDER_GET } from './orders.mutations';

type Vars = {
  id: ID;
};

const useOrders = () => {
  return useMutation<{ specificOrder: SpecificOrder }, Vars>(ORDER_GET);
};

export default useOrders;
