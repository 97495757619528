import React, { createContext, useState, useCallback, useEffect } from 'react';
import useOrder from 'hooks/orders/queries/useOrder';
import { Order, SpecificOrder } from '../types/orders.types';

interface ExpandedOrdersContextType {
  orders: Order[];
  setOrders: (orders: Order[]) => void;
  expandedOrders: SpecificOrder[];
  getExpandedOrder: (id: number) => {
    expandedOrder: SpecificOrder | null;
    isLoadingExpandedOrder: boolean;
  };
}

export const ExpandedOrdersContext = createContext<ExpandedOrdersContextType | undefined>(
  undefined
);

/**
 * ExpandedOrdersProvider
 *
 * Context that manages the state of expanded orders and their on-demand loading.
 *
 * Functionality:
 * - Maintains a cache of expanded orders to avoid repeated useOrder requests
 * - Manages loading states for each individual order (isLoadingExpandedOrder)
 * - Clears the cache (expandedOrders) when the main order list (orders) changes
 *
 * Main states:
 * - orders: Array of basic orders (overview)
 * - expandedOrders: Cache of orders with complete details
 * - selectedOrderId: ID of the order currently being loaded
 * - loadingOrderId: ID of the order in loading state
 *
 * 1. Using the hook in components:
 *    const { getExpandedOrder } = useExpandedOrders();
 *    const { expandedOrder, isLoadingExpandedOrder } = getExpandedOrder(orderId);
 *
 * 2. To update the main order list (useOrders.tsx):
 *    const { setOrders } = useExpandedOrders();
 *    setOrders(newOrders); // This will clear the expanded orders cache
 */

const ExpandedOrdersProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [expandedOrders, setExpandedOrders] = useState<SpecificOrder[]>([]);
  const [selectedOrderId, setSelectedOrderId] = useState<number | null>(null);
  const [loadingOrderId, setLoadingOrderId] = useState<number | null>(null);

  // Only call useOrder when selectedOrderId exists
  const { data: orderData, loading } = useOrder(selectedOrderId || undefined);

  useEffect(() => {
    if (!selectedOrderId) return;

    if (loading) {
      setLoadingOrderId(selectedOrderId);
    } else if (orderData?.specificOrder) {
      const newOrder = orderData.specificOrder;
      setExpandedOrders(prev => [...prev, newOrder]);
      setLoadingOrderId(null);
    }
  }, [orderData, loading, selectedOrderId]);

  // Clear expandedOrders when orders change
  useEffect(() => {
    setExpandedOrders([]);
    setSelectedOrderId(null);
    setLoadingOrderId(null);
  }, [orders]);

  const getExpandedOrder = useCallback((id: number) => {
    // First look in the expandedOrders array
    const existingOrder = expandedOrders.find(order => order.id === id);

    if (existingOrder) {
      return {
        expandedOrder: existingOrder,
        isLoadingExpandedOrder: false
      };
    }

    // If not found, trigger the query
    if (!loadingOrderId) {
      setSelectedOrderId(id);
    }

    return {
      expandedOrder: null,
      isLoadingExpandedOrder: loadingOrderId === id || loading
    };
  }, [expandedOrders, loading, loadingOrderId]);

  const value = {
    orders,
    setOrders,
    expandedOrders,
    getExpandedOrder,
  };

  return (
    <ExpandedOrdersContext.Provider value={value}>
      {children}
    </ExpandedOrdersContext.Provider>
  );
};

export default ExpandedOrdersProvider;
