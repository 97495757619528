import { gql } from '@apollo/client';
import fragments from '../orders.fragments';

export const ORDER_CREATE = gql`
  mutation createOrder($input: UserInput!) {
    createOrder(input: $input)
      @rest(type: "SpecificOrder", path: "/orders/", method: "POST") {
      ...SpecificOrderFragment
    }
  }

  ${fragments.specificOrder}
`;

export const ORDER_CALCULATE = gql`
  mutation calculateOrder($input: UserInput!) {
    calculateOrder(input: $input)
      @rest(
        type: "CalculatedOrder"
        path: "/orders/calculate/"
        method: "POST"
      ) {
      coupon
      details
      discount
      shipping_price
      shipping_discount
      selected_shipping_method
      shipping_method_options
      subtotal
      total
      errors
      max_delivery_date
      min_delivery_date
      expected_delivery_date
      iva
      cdso
      orbis
    }
  }
`;

export const ORDER_UPDATE = gql`
  mutation updateOrder($id: String!, $input: UserInput!) {
    updateOrder(id: $id, input: $input)
      @rest(
        type: "SpecificOrder"
        path: "/orders/{args.id}/"
        method: "PATCH"
      ) {
      ...SpecificOrderFragment
    }
  }

  ${fragments.specificOrder}
`;

/**
 * ? This mutation has a different cache type than the one that is actually
 * returned by the API in order to trick Apollo into merging that response
 * to the Order cache instead of the SpecificOrder cache
 */
export const ORDER_STATUS_UPDATE = gql`
  mutation updateOrderStatus($id: String!, $input: UserInput!) {
    updateOrderStatus(id: $id, input: $input)
      @rest(type: "Order", path: "/orders/{args.id}/", method: "PATCH") {
      id
      order_status
      payment_status
    }
  }
`;
export const PURCHASE_ORDER_STATUS_UPDATE = gql`
  mutation updatePurchaseOrderStatus($id: String!, $input: UserInput!) {
    updatePurchaseOrderStatus(id: $id, input: $input)
      @rest(
        type: "Order"
        path: "/purchase-orders/{args.id}/"
        method: "PATCH"
      ) {
      id
      status
      details
    }
  }
`;

export const ORDER_ADD_PRESCRIPTIONS = gql`
  mutation orderAddPrescriptions($id: String!, $input: UserInput!) {
    orderAddPrescriptions(id: $id, input: $input)
      @rest(
        type: "SpecificOrder"
        path: "/orders/{args.id}/"
        method: "PATCH"
        bodySerializer: "fileEncode"
      ) {
      id
      prescriptions
    }
  }
`;

export const ORDER_DELETE = gql`
  mutation deleteOrder($id: String!) {
    deleteOrder(id: $id)
      @rest(
        type: "SpecificOrder"
        path: "/orders/{args.id}/"
        method: "DELETE"
      ) {
      id
    }
  }
`;

export const ORDER_SEND_QUOTE = gql`
  mutation OrderQuote($input: OrderQuoteInput!) {
    orderQuote(input: $input)
      @rest(type: "OrderQuote", path: "/orders/quote/", method: "POST") {
      cart_url
    }
  }
`;

export const ORDER_RESEND_EMAIL = gql`
  mutation OrderResendEmail($input: OrderResendEmailInput!) {
    orderResendEmail(id: $id, input: $input)
      @rest(
        type: "OrderResendEmail"
        path: "/orders/{args.id}/send-email/"
        method: "POST"
      ) {
      email
    }
  }
`;

/**
 * Invoicing mutations
 */

export const ORDER_INVOICE_CREATE = gql`
  mutation createInvoiceOrder($id: String!, $input: OrderInvoiceCreateInput!) {
    createInvoiceOrder(id: $id, input: $input)
      @rest(
        type: "SpecificOrder"
        path: "/orders/{args.id}/create-invoice/"
        method: "POST"
      ) {
      ...SpecificOrderFragment
    }
  }
  ${fragments.specificOrder}
`;

export const ORDER_INVOICE_CANCEL = gql`
  mutation cancelInvoiceOrder($id: String!, $input: OrderInvoiceCreateInput!) {
    cancelInvoiceOrder(id: $id, input: $input)
      @rest(
        type: "SpecificOrder"
        path: "/orders/{args.id}/cancel-invoice/"
        method: "POST"
      ) {
      ...SpecificOrderFragment
    }
  }
  ${fragments.specificOrder}
`;

export const ORDER_GET = gql`
  mutation getOrder($id: String!) {
    specificOrder(id: $id)
      @rest(type: "SpecificOrder", path: "/orders/{args.id}/") {
      ...SpecificOrderFragment
    }
  }
  ${fragments.specificOrder}
`;

export const ORDER_CREATE_FOLIO_INVOICING = gql`
  mutation createFolioInvocingOrder(
    $id: String!
    $input: createFolioInvocingInput!
  ) {
    updateOrder(id: $id, input: $input)
      @rest(
        type: "createFolioInvocingOrder"
        path: "/orders/{args.id}/create-invoice/"
        method: "POST"
      ) {
      folio
      fiscal_uuid
    }
  }
`;

export const ORDER_ML_GUIDE = gql`
  mutation orderMLGuide($id: String!, $input: OrderMLGuideInput!) {
    orderMLGuide(id: $id, input: $input)
      @rest(
        type: "orderMLGuide"
        path: "/orders/{args.id}/generate-guide/"
        method: "POST"
      ) {
      filename
      pdf
    }
  }
`;

export const ADD_TO_WAITING_LIST = gql`
  mutation addToWaitingList($input: WaitingListInput!) {
    addToWaitingList(input: $input)
      @rest(type: "WaitingList", path: "/waiting-lists/", method: "POST") {
      id
      email
      phone
      product
      created_at
    }
  }
`;
