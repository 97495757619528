import { useMutation } from '@apollo/client';
import { ORDER_CREATE_FOLIO_INVOICING } from 'hooks/orders/mutations/orders.mutations';

type Data = {
  folio: string | number;
  fiscal_uuid?: string | number;
};

type Vars = {};

const useOrderAddFolioInvoicing = () => {
  return useMutation<Data, Vars>(ORDER_CREATE_FOLIO_INVOICING, {
    refetchQueries: ['ordersPaginated', 'specificOrder'],
  });
};

export default useOrderAddFolioInvoicing;
