import { useMutation } from '@apollo/client';
import { ORDER_RESEND_EMAIL } from './orders.mutations';

type Data = {
  email: string;
};

type Vars = {};

const useOrderResendEmail = () => {
  return useMutation<Data, Vars>(ORDER_RESEND_EMAIL);
};

export default useOrderResendEmail;
