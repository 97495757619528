/* eslint-disable no-debugger */
import { ValuesOrderStatusEnum } from 'components/orders/orders.enums';
import { useToast } from 'hooks';
import { useOrderUpdateStatus } from 'hooks/orders/mutations';
import React, { useState } from 'react';
import { Order } from 'types/orders.types';
import { SubscriptionOrder } from 'types/subscriptions.types';

type BulkOrderStatusContextValue = {
  bulkOrders: (Order | SubscriptionOrder)[];
  setBulkOrders: React.Dispatch<React.SetStateAction<(Order | SubscriptionOrder)[]>>;
  bulkUpdateOrderStatus: ({ bulkOrders, order_status }: {
    bulkOrders: Order[];
    order_status: ValuesOrderStatusEnum;
  }) => Promise<void>
};

const initialBulkOrderStatusValue: BulkOrderStatusContextValue = {
  bulkOrders: [],
  setBulkOrders: () => { },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  bulkUpdateOrderStatus: async ({ bulkOrders = [], order_status = ValuesOrderStatusEnum.DELIVERED }) => { },
};

export const BulkOrderStatusContext =
  React.createContext<BulkOrderStatusContextValue>(initialBulkOrderStatusValue);

const BulkOrderStatusProvider: React.FC = ({ children }) => {
  const [openToast] = useToast();

  const [updateOrderStatus] = useOrderUpdateStatus();

  const [bulkOrders, setBulkOrders] = useState<(Order | SubscriptionOrder)[]>([])

  const handleLogisticsChange = async (
    { order, order_status }: { order: Order, order_status: ValuesOrderStatusEnum }) => {
    const payload = {
      id: order.id,
      order_status,
    };

    try {
      await updateOrderStatus({
        variables: {
          id: order.id,
          input: payload,
        },
      });
    } catch (error) {
      openToast(`Error al actualizar orden ${order.id}.`, { type: 'error' });
    }
  };

  const bulkUpdateOrderStatus = async (
    { bulkOrders, order_status }: { bulkOrders: Order[], order_status: ValuesOrderStatusEnum }
  ) => {

    await bulkOrders.reduce<Promise<void>>(async (promise, order) => {
      await promise;

      return handleLogisticsChange({
        order,
        order_status
      })
    }, Promise.resolve());

    setBulkOrders([]);
  }


  const BulkOrderStatusContextValue: BulkOrderStatusContextValue = React.useMemo(
    () => ({
      bulkOrders,
      setBulkOrders,
      bulkUpdateOrderStatus,
    }),
    [bulkOrders]
  );

  return (
    <BulkOrderStatusContext.Provider value={BulkOrderStatusContextValue}>
      {children}
    </BulkOrderStatusContext.Provider>
  );
};

export default BulkOrderStatusProvider;
