import React from 'react';
import { Provider as Redux } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { BreakpointProvider } from 'react-socks';
import { ErrorBoundary } from '@sentry/react';
import BrokenScreen from 'components/brokenScreen/BrokenScreen';
import { ExpandedOrdersProvider, BulkOrderStatusProvider } from 'context';
import store from './store';
import client from './api/ApolloClient';
import Auth from './context/Auth';
import Alert from './context/Alert';
import Toast from './context/Toast';
import Confirmation from './context/Confirmation';
import Storehouse from './context/Storehouse';
import ColumnsToShowProvider from './context/ColumnsToShow';
import PurchaseCartProvider from './context/PurchaseCart';
import PanelHeader from './context/PanelHeader';
import Sidebar from './context/Sidebar';


type Props = {
  children: React.ReactNode;
};

const Providers: React.FC<Props> = ({ children }) => {
  return (
    <BreakpointProvider>
      <ErrorBoundary fallback={BrokenScreen}>
        <Toast>
          <Confirmation>
            <Alert>
              <ApolloProvider client={client}>
                <Router>
                  <Auth>
                    <Storehouse>
                      <ColumnsToShowProvider>
                        <PurchaseCartProvider>
                          <BulkOrderStatusProvider>
                            <ExpandedOrdersProvider>
                              <PanelHeader>
                                <Sidebar>
                                  <Redux store={store}>{children}</Redux>
                                </Sidebar>
                              </PanelHeader>
                            </ExpandedOrdersProvider>
                          </BulkOrderStatusProvider>
                        </PurchaseCartProvider>
                      </ColumnsToShowProvider>
                    </Storehouse>
                  </Auth>
                </Router>
              </ApolloProvider>
            </Alert>
          </Confirmation>
        </Toast>
      </ErrorBoundary>
    </BreakpointProvider>
  );
};

export default Providers;
