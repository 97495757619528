import { useMutation } from '@apollo/client';
import { SpecificOrder } from 'types/orders.types';
import { SpecificSubscriptionOrder } from 'types/subscriptions.types';
import { ORDER_INVOICE_CREATE } from './orders.mutations';

type Vars = {
  order: SpecificOrder | SpecificSubscriptionOrder;
};

const useNewOrderInvoiceCreate = () => {
  return useMutation<Vars>(ORDER_INVOICE_CREATE);
};

export default useNewOrderInvoiceCreate;
