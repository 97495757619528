import { colors } from 'types/framework.types';
import { PaymentMethodEnum } from 'types/paymentMethods.types';

type ColorsMap = { [key: string]: colors };
type TextsMap = Record<string, string>;

export const enum ValuesOrderStatusEnum {
  PENDING_APPROVAL = 'pending_approval',
  QUOTED = 'quoted',
  APPROVED = 'approved',
  BOUGHT = 'bought',
  IN_PROCESS = 'in_process',
  READY = 'ready',
  SHIPPED = 'shipped',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
  DELAYED = 'delayed',
  SHORTAGE = 'shortage',
}

export const ValuesOrder = Object.freeze([
  ValuesOrderStatusEnum.PENDING_APPROVAL,
  ValuesOrderStatusEnum.QUOTED,
  ValuesOrderStatusEnum.APPROVED,
  ValuesOrderStatusEnum.BOUGHT,
  ValuesOrderStatusEnum.IN_PROCESS,
  ValuesOrderStatusEnum.READY,
  ValuesOrderStatusEnum.SHIPPED,
  ValuesOrderStatusEnum.DELIVERED,
  ValuesOrderStatusEnum.CANCELLED,
  ValuesOrderStatusEnum.DELAYED,
  ValuesOrderStatusEnum.SHORTAGE,
]);

export const ValuesBulkOrder = Object.freeze([
  ValuesOrderStatusEnum.READY,
  ValuesOrderStatusEnum.SHIPPED,
  ValuesOrderStatusEnum.DELIVERED,
])

export const OperatorAllyValuesOrder = Object.freeze([
  ValuesOrderStatusEnum.PENDING_APPROVAL,
  ValuesOrderStatusEnum.APPROVED,
  ValuesOrderStatusEnum.IN_PROCESS,
  ValuesOrderStatusEnum.READY,
  ValuesOrderStatusEnum.DELIVERED,
  ValuesOrderStatusEnum.CANCELLED,
  ValuesOrderStatusEnum.QUOTED,
  ValuesOrderStatusEnum.BOUGHT,
]);

export const InplantAllyValuesOrder = Object.freeze([
  ValuesOrderStatusEnum.PENDING_APPROVAL,
  ValuesOrderStatusEnum.APPROVED,
  ValuesOrderStatusEnum.IN_PROCESS,
  ValuesOrderStatusEnum.READY,
  ValuesOrderStatusEnum.SHIPPED,
  ValuesOrderStatusEnum.DELIVERED,
  ValuesOrderStatusEnum.CANCELLED,
  ValuesOrderStatusEnum.QUOTED,
  ValuesOrderStatusEnum.BOUGHT,
]);

export const ValuesOrderCreate = Object.freeze([
  ValuesOrderStatusEnum.PENDING_APPROVAL,
  ValuesOrderStatusEnum.QUOTED,
  ValuesOrderStatusEnum.APPROVED,
]);

export const ValuesOrderCreateHospitaria = Object.freeze([
  ValuesOrderStatusEnum.PENDING_APPROVAL,
  ValuesOrderStatusEnum.QUOTED,
  ValuesOrderStatusEnum.APPROVED,
]);

export const ValuesOrderEdit = Object.freeze([
  ValuesOrderStatusEnum.PENDING_APPROVAL,
  ValuesOrderStatusEnum.QUOTED,
  ValuesOrderStatusEnum.APPROVED,
  ValuesOrderStatusEnum.BOUGHT,
]);

export const ValuesShippingMethods = Object.freeze(['local', 'express']);

const IndexShippingMethodsMap: Record<string, string> = {
  standard: 'Nacional',
  express: 'Nacional',
  local: 'Local',
};

export const IndexShippingMethods = Object.freeze(IndexShippingMethodsMap);

const IndexShippingColorsMap: Record<string, colors> = {
  Nacional: 'softPurple',
  Local: 'softCyan',
};

export const IndexShippingColors = Object.freeze(IndexShippingColorsMap);

const LocalClassificationMap: Record<string, string> = {
  'San Pedro Garza Garcia': 'Local A',
  'San Pedro Garza García': 'Local A',
  Monterrey: 'Local B',
  'Santa Catarina': 'Local B',
  Guadalupe: 'Local B',
  'San Nicolas': 'Local B',
  'San Nicolás': 'Local B',
  Apodaca: 'Local C',
  Escobedo: 'Local C',
  García: 'Local C',
  Garcia: 'Local C',
  Santiago: 'Local C',
};

export const LocalClassification = Object.freeze(LocalClassificationMap);

export const IndexOrders = Object.freeze({
  Quoted: 0,
  Approved: 1,
  InProcess: 2,
  Ready: 3,
  Shipped: 4,
  Delivered: 5,
  Cancelled: 6,
  Delayed: 7,
  Shortage: 8,
});

export const ColorsOrder: ColorsMap = Object.freeze({
  pending_approval: 'softYellow',
  quoted: 'softGrey',
  approved: 'softCyan',
  in_process: 'softBlue',
  ready: 'softViolet',
  shipped: 'softPurple',
  delivered: 'softGreen',
  cancelled: 'softRed',
  delayed: 'softOrange',
  shortage: 'boldGrey',
  partial: 'softYellow',
  bought: 'hardYellow',
});

const tagsOrderMap: Record<string, colors> = {
  origin: 'softGrey',
  requires_prescription: 'softOrange',
  invoicing_status: 'softPurple',
  payment_method: 'softGreen',
  is_subscription: 'softCyan',
  // has_enlace_vital: 'softYellow',
  has_cdso: 'softBlue',
  has_orbis: 'softBlue',
  has_loyalty_error: 'softRed',
  has_card_without_loyalty_discount: 'softYellow',
  is_corne: 'softBlue',
};

export const TagsOrder = Object.freeze(tagsOrderMap);

const tagsOrderLabelMap: Record<string, string> = {
  'link de pago (mercadopago)': 'Link de pago MP',
  requiere_factura: 'Requiere factura',
  card_no_discount: 'Alerta LP',
  enlace_vital_descripyion: 'Enlace Vital',
  amazon_easyship: 'Amazon Easyship',
};

export const TagLabelOrder = Object.freeze(tagsOrderLabelMap);

const fileTypesMap: Record<string, string> = {
  'application/pdf': 'fas fa-file-pdf fa-2x',
  'image/jpg': 'fas fa-file-image fa-2x',
  'image/jpeg': 'fas fa-file-image fa-2x',
  'image/png': 'fas fa-file-image fa-2x',
};

export const FileTypes = Object.freeze(fileTypesMap);

export const TextsCreateOrder: TextsMap = Object.freeze({
  pending_approval: 'Por aprobar',
  quoted: 'Cotizada',
  approved: 'Aprobada',
});

export const TextsOrder: TextsMap = Object.freeze({
  pending_approval: 'Por aprobar',
  quoted: 'Cotizada',
  approved: 'Aprobada',
  in_process: 'En proceso',
  ready: 'Lista',
  shipped: 'Enviada',
  delivered: 'Entregada',
  cancelled: 'Cancelada',
  delayed: 'Retrasada',
  shortage: 'Escasez',
  bought: 'Comprado',
});
export const TextsShippingMethodOrder: TextsMap = Object.freeze({
  local: 'Local',
  express: 'Nacional',
});
const { REACT_APP_ENV } = process.env;

const getShippingLocal = () => {
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'beta') {
    return '4';
  }
  return '8';
};
const getShippingNational = () => {
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'beta') {
    return '6';
  }
  return '9';
};
const shippingLocal: string = getShippingLocal();
const shippingNational: string = getShippingNational();

export const ValuesShippingMethodOrder: TextsMap = Object.freeze({
  local: shippingLocal,
  express: shippingNational,
});

export const ValuesPayment = Object.freeze([
  'unpaid',
  'credit',
  'paid',
  'rejected',
  'refunded',
]);

export const IndexPayment = Object.freeze({
  Unpaid: 0,
  Credit: 1,
  Paid: 2,
  Rejected: 4,
});

export const ColorsPayment: ColorsMap = Object.freeze({
  unpaid: 'hardGrey',
  credit: 'hardCyan',
  paid: 'hardGreen',
  rejected: 'hardRed',
  refunded: 'softOrange',
  in_process: 'softOrange',
});

export const TextsPayment: { [key: string]: string } = Object.freeze({
  unpaid: 'No pagado',
  credit: 'Crédito',
  paid: 'Pagado',
  rejected: 'Rechazado',
  refunded: 'Devolucion',
  in_process: 'En Proceso',
});
export const TextsPaymentMethods: { [key: string]: string } = Object.freeze({
  Cash: 'Efectivo',
  'Terminal de MercadoPago': 'Terminal de pago',
});

export enum PaymentMethods {
  Cash = 'Cash',
  CreditCard = 'Credit card',
  LinkMP = 'Link de Pago (MercadoPago)',
}

export enum PaymentMethodsId {
  Cash = 1,
}

export const MPRejectionMessage: Record<string, string> = Object.freeze({
  cc_rejected_bad_filled_card_number: 'Revisar el número de tarjeta.',
  cc_rejected_bad_filled_date: 'Revisar la fecha de vencimiento.',
  cc_rejected_bad_filled_other: 'Revisar los datos de la tarjeta.',
  cc_rejected_bad_filled_security_code:
    'Revisar el código de seguridad de la tarjeta.',
  cc_rejected_blacklist: 'No se pudo procesar el pago.',
  cc_rejected_call_for_authorize:
    'El monto a pagar debe autorizarse ante el banco.',
  cc_rejected_card_disabled: 'Tarjeta desactivada. ',
  cc_rejected_card_error: 'No se pudo procesar el pago.',
  cc_rejected_duplicated_payment:
    'Pago duplicado. Ya se hizo un pago por este valor.',
  cc_rejected_high_risk: 'El pago fue rechazado.',
  cc_rejected_insufficient_amount: 'Fondos insuficientes.',
  cc_rejected_invalid_installments:
    'La institución bancaria no procesa pagos en cuotas.',
  cc_rejected_max_attempts: 'Límite de intentos permitidos.',
  cc_rejected_other_reason: 'La institución bancaria no procesó el pago.',
});

export enum Origins {
  app = 'app',
  panel = 'panel',
  website = 'website',
}

export enum Tags {
  isDoctor = 'is_doctor',
  requiresPrescription = 'requires_prescription',
  invoiced = 'facturada',
  requiresInvoice = 'requiere_factura',
  invoicedForClients = 'facturada_clientes',
  isSubscription = 'is_subscription',
  // hasEnlaceVital = 'has_enlace_vital',
  isCorne = 'is_corne',
  hasCdso = 'has_cdso',
  has_loyalty_error = 'has_loyalty_error',
  is_special_pickup = 'is_special_pickup',
}

export const ValuesTags = Object.freeze([
  Origins.app,
  Origins.panel,
  Origins.website,
  Tags.isDoctor,
  Tags.requiresPrescription,
  Tags.invoiced,
  Tags.requiresInvoice,
  Tags.invoicedForClients,
  Tags.isSubscription,
  // Tags.hasEnlaceVital,
  Tags.hasCdso,
  Tags.has_loyalty_error,
  Tags.isCorne,
  Tags.is_special_pickup,
]);

export const OpsValuesTags = Object.freeze([
  Tags.requiresPrescription,
  Tags.isSubscription,
  Tags.is_special_pickup,
  Origins.app,
  Origins.panel,
  Origins.website
  // Tags.hasEnlaceVital,
]);

export const TextsTags: Record<string, string> = Object.freeze({
  [Tags.isDoctor]: 'Doctor',
  [Tags.requiresPrescription]: 'Receta',
  [Origins.website]: 'Website',
  [Origins.app]: 'App',
  [Origins.panel]: 'Panel',
  [Tags.requiresInvoice]: 'Requiere factura',
  [Tags.invoiced]: 'Facturada',
  [Tags.invoicedForClients]: 'Facturada para clientes',
  [Tags.isSubscription]: 'Suscripción',
  // [Tags.hasEnlaceVital]: 'Enlace Vital',
  [Tags.hasCdso]: 'CDSO',
  [Tags.has_loyalty_error]: 'Alerta LP',
  [Tags.isCorne]: 'Corne',
  [Tags.is_special_pickup]: 'Recolección especial',
});

export const PICKUP_METHOD = {
  id: 99,
  company: '',
  method: 'Recoger en tienda',
  price: 0,
  max_delivery_days: 0,
  min_delivery_days: 0,
};

export type SelectType = { value: string | number; label: string };

export const cancelationReasons: readonly SelectType[] = Object.freeze([
  { value: 0, label: 'Producto en desabasto' },
  { value: 1, label: 'Demora en entrega de orden' },
  { value: 2, label: 'Orden duplicada' },
  { value: 3, label: 'Orden errónea' },
  { value: 4, label: 'Problema al redimir cupón' },
  { value: 5, label: 'No pagada' },
  { value: 6, label: 'No entrego receta' },
  { value: 7, label: 'Devolución' },
  { value: 8, label: 'Cancelación desde Market Place' },
  { value: 9, label: 'Otro' },
]);

export const marketPlacesOptions: readonly SelectType[] = Object.freeze([
  { value: 0, label: PaymentMethodEnum.AMAZON },
  { value: 1, label: PaymentMethodEnum.COPPEL },
  { value: 2, label: PaymentMethodEnum.LINIO },
  { value: 3, label: PaymentMethodEnum.LIVERPOOL },
  { value: 4, label: PaymentMethodEnum.MERCADO_LIBRE },
  { value: 5, label: PaymentMethodEnum.WALMART },
]);
