import { useMutation } from '@apollo/client';
import { SpecificOrder } from 'types/orders.types';
import { ORDER_UPDATE } from 'hooks/orders/mutations/orders.mutations';

const useOrderUpdate = () => {
  return useMutation<{ updateOrder: SpecificOrder }>(ORDER_UPDATE, {
    refetchQueries: ['ordersPaginated'],
  });
};

export default useOrderUpdate;
